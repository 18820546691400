.for_main_img {
  padding: 0;
}
.main_img_white {
  height: 93vh;
  object-fit: contain;
}
.main_img {
  height: 93vh;
  object-fit: cover;
}
.close_img,
.close_img:hover {
  position: absolute;
  z-index: 20;
  top: 1.5vh;
  right: 2%;
  height: 5.4vh;
  opacity: 1;
}
.close_img:hover {
  opacity: 0.8;
}
.strel,
.strel:focus {
  color: #000;
  position: absolute;
  width: 5%;
  top: 93vh;
  height: 7vh;
  opacity: 1;
}
.strel:hover {
  color: #000;
  opacity: 0.7;
}

.main_footer {
  font-weight: 300;
  font-size: 120%;
  width: 100%;
  height: 7vh;
}
.name_for_img,
.name_for_img:hover,
.name_for_img:active {
  color: #000;
  text-decoration: none;
  padding-top: 1.5vh;
  position: absolute;
  margin: 0;
  left: 75%;
  width: auto;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
}
.carousel-inner {
  overflow: hidden;
}
.strel_img {
  height: 80%;
}
.surprise {
  position: absolute;
  height: 95vh;
  width: 100%;
  top: 0;
  z-index: 15;
}

/* **************************************** */

@media (max-width: 960px) {
  .carousel-control-prev {
    left: 8px;
  }
  .carousel-control-next {
    right: 8px;
  }
  .name_for_img,
  .name_for_img:hover,
  .name_for_img:active {
    color: #000;
    /* height: 5vh; */
    padding: 0;
    position: absolute;
    margin: 0;
    top: 68vh;
    left: 15%;
    width: auto;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
  }
  .for_main_img {
    margin-top: 176px;
    height: 68vh;
  }
  .main_img {
    height: 67vh;
  }

  .main_img_white {
    height: 67vh;
    object-fit: contain;
  }
  .surprise {
    position: absolute;
    height: 67vh;
    width: 100%;
    top: 0;
    z-index: 15;
  }
  .strel,
  .strel:focus {
    color: #000;
    position: absolute;
    width: 4%;
    top: 67.5vh;
    height: 7vh;
    opacity: 1;
  }
  @media (max-height: 400px) {
    .name_for_img,
    .name_for_img:hover,
    .name_for_img:active {
      font-size: 6vh;
      top: 67vh;
    }
  }
}
