@media (max-width: 960px) {
  .navbar-m {
    margin-top: -30px;
    background-color: white;
    z-index: 50;
  }
  .navbar {
    background-color: #fff;
    color: #000;
    position: absolute;
    margin: 0px;
    min-height: 120px;
    margin-left: -15px;
    padding-right: 10px;
    width: 100%;
    z-index: 100;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: #fff;
  }
  .en {
    position: absolute;
    top: 1.6vh;
    left: 0;
    background: none;
    border: none;
    font-weight: 300;
    font-size: 20px;
  }
  .en:hover {
    font-weight: 400;
  }
  .for_name {
    padding: 10px 10% 0 0;
}
  .name,
  .name:hover {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.1;
    display: block;
    text-align: left;
    color: #000;
    text-decoration: none;
  }
  .grey,
  .grey:hover {
    color: #808080;
  }
  .anna {
    padding-left: 40px;
  }
  .utp {
    color: #000;
    padding-top: 17px;
    text-align: left;
    display: block;
    font-size: 14.5px;
  }
  .call-block{
    height: 46px;
    float: left;
  }
  .call {
    font-size: 12px;
    color: #fff;
    display: block;
    padding: 5px 8px;
    width: 241px;
    margin-bottom: 15px;
    /* margin: 0 115px 15px 0px; */
    background-color: #000;
    border-radius: 15px;
  }
  .call:hover {
    color: #ffffff;
    background-color: #808080;
    text-decoration: none;
  }
  .footer-block {
    /* width: 245px; */
    float: left;
  }
  .menu {
    padding: 10px 10% 0 0;
    min-height: 70px;
    text-align: end;
  }
  .hr {
    color: #000;
    float: right;
    margin-right: 5px;
    border: 0.6px solid black;
    margin-bottom: 0;
    size: 1px;
    width: 90%;
    display: block;
  }
  .item {
    color: #000;
    font-size: 17px;
    font-weight: 600;
    padding-top: 4px;
    margin: 0 0.2vw;
    cursor: pointer;
    display: inline-block;
  }
  .item:hover {
    color: #000;
    text-decoration: none;
  }
  .item_hover:hover {
    border-bottom: 2px dashed #000;
  }
  .activve {
    border-bottom: 2px solid #000;
  }
  .under_items {
    margin-top: 8%;
    padding-left: 5%;
  }

  .small_item {
    display: block;
    text-decoration: none;
    color: #000;
    font-size: 15px;
    cursor: pointer;
  }
  .small_item:hover {
    text-decoration: none;
    color: #000;
  }
  .small_item_hover:hover {
    font-weight: 600;
  }
  .active_small {
    font-weight: 700;
  }
  .small_item_main {
    font-size: 19px;
  }
  .sety {
    margin-right: 14px;
    width: 30px;
    height: 30px;
  }
  .tel {
  letter-spacing: 0px;
}
  .footer {
    position: static;
    font-weight: 300;
    font-size: 20px;
    width: 100%;
    height: 100px;
    text-align: end;
    bottom: 0;
  }
  .navbar-light .navbar-nav .nav-link {
    color: rgb(0, 0, 0);
  }
  .nav-link {
    padding: 0.6rem 0;
  }
  .bg-light {
    background-color: #fff !important;
  }
  /* .navbar-nav .nav-link { */
    /* padding-left: 2rem; */
  /* } */
}

@media (max-width: 450px) {
  .name,
  .name:hover {
    font-size: 34px;
  }
  .sety {
    margin-right: 14;
    width: 24px;
    height: 24px;
  }
  .call {
    width: 240px;
  }
  .tel {
    letter-spacing: 0px;
  }
  .footer {
    font-size: 19px;
    bottom: 7px;
  }
}
