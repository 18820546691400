.for_main_img_s {
  padding: 0;
  max-height: 93vh;
}
.main_img_s {
  height: 93vh;
  object-fit: cover;
}
.white_contacts {
  /* height: 480px; */
  width: 100%;
  position: absolute;
  top: 33vh;
  height: 60vh;
  background-color: rgba(255, 255, 255, 0.85);
  font-weight: 600;
  font-size: 17px;
  padding: 2% 5% 0 4%;
}
.white_contacts p {
  margin: 1.5% 0;
}
.form-control,
.form-control:focus,
input {
  border: none;
  color: #000;
  border-radius: 0;
  background-color: rgb(243, 243, 242);
}
input:focus,
textarea:focus,
a.active:focus,
a:focus,
button:focus,
.btn:focus {
  outline: 0 !important;
  outline-color: transparent !important;
  outline-width: 0 !important;
  outline-style: none !important;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important;
}
.form-control:focus {
  background-color: rgb(230, 230, 223);
}
.limit_me {
  max-height: 130px;
}
.form-group {
  margin-bottom: 2.5%;
}
.btn {
  color: #fff;
  border-radius: 0;
  background-color: #000;
  font-size: 16px;
  padding: 1px 18px;
  /* margin-top: -40px; */
}
.btn_hover:hover {
  color: rgb(184, 183, 183);
}
.btn_hoverD:hover {
  color: #fff;
}
input:-internal-autofill-selected {
  background-color: rgb(243, 243, 242) !important;
}
.static_li {
  list-style-type: none;
  padding-bottom: 0.3%;
}
.static_li a,
.static_li a:hover {
  color: #000;
  text-decoration: none;
}
.answer {
  display: inline-block;
  padding: 0;
  width: 70%;
  margin: 0 4%;
  margin-top: -4px;
}

@media (max-width: 400px) {
  .white_contacts {
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 30%,
      rgba(255, 255, 255, 0.85)
    );
  }
}

@media (max-width: 1600px) {
  .answer {
    width: 60%;
  }
}
@media (max-width: 1200px) {
  .answer {
    width: 50%;
  }
}
.alert-success,
.alert-danger {
  margin: 0;
  font-size: 14px;
  padding: 3px 5px;
}
.alert-dismissible .close {
  padding: 2px 5px;
}
@media (max-width: 960px) {
  .for_main_img_s {
    margin-top: 135px;
    /* height: 00%; */
  }
  .main_img_s {
    height: 100%;
    object-fit: cover;
  }
  .white_contacts {
    width: 100%;
    margin-top: 10vh;
    position: sticky;
    top: 15vh;
    height: 100%;
    font-weight: 600;
    font-size: 19px;
    padding: 2% 5% 3% 5%;
  }
}
