.root_menu {
  height: 100vh;
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
}
.for_max_width {
  max-width: 380px;
}
.for_name {
  padding: 10px 12% 0 0;
}
.en {
  position: absolute;
  top: 2.5vh;
  left: 7%;
  color: #000;
  background: none;
  border: none;
  font-weight: 300;
  font-size: 20px;
}
.en:hover {
  color: #000;
  text-decoration: none;
}
.href {
  font-weight: 600;
  color: #000;
  background: none;
  border: none;
}
.href:hover {
  /* font-weight: 600; */
  color: #575656;
  text-decoration: none;
}
.name,
.name:hover {
  font-size: 44px;
  font-weight: 700;
  line-height: 1.4;
  display: block;
  text-align: end;
  color: #000;
  text-decoration: none;
}
.grey,
.grey:hover {
  color: #808080;
}
.utp {
  color: #000;
  padding-top: 20px;
  text-align: end;
  display: block;
  font-size: 18px;
}
.call-block{
  height: 46px;
  padding-right: 12%;
  float: right;
}
.call {
  font-size: 14px;
  color: #fff;
  display: block;
  padding: 5px 8px;
  text-align: center;
  width: 281px;
  margin-bottom: 15px;
  /* margin: 0 45px 15px 84px; */
  background-color: #000;
  border-radius: 15px;
}
.call:hover {
  color: #ffffff;
  background-color: #808080;
  text-decoration: none;
}
.footer-block {
  /* width: 245px; */
  float: right;
  padding-right: 12%;
}
.menu {
  padding: 10px 11.5% 0 0;
  min-height: 70px;
  text-align: end;
}
.hr {
  color: #000;
  float: right;
  margin-right: 2px;
  border: 0.6px solid #000;
  margin-bottom: 0;
  size: 1px;
  width: 91%;
  display: block;
}
.item {
  color: #000;
  font-size: 16.3px;
  font-weight: 600;
  padding-top: 4px;
  padding-bottom: 2px;
  margin: 0 4px;
  cursor: pointer;
  display: inline-block;
}
.item:hover {
  color: #000;
  text-decoration: none;
}
.item_hover:hover {
  border-bottom: 2px dashed #000;
  padding-bottom: 0;
}
.activve {
  border-bottom: 2px solid #000;
}
.activve {
  border-bottom: 2px solid #000;
  padding-bottom: 0;
}
.under_items {
  margin-top: 8%;
}
.pad {
  padding-left: 7%;
}
.small_item {
  display: block;
  text-decoration: none;
  color: #000;
  font-size: 15px;
  cursor: pointer;
}
.small_item:hover {
  text-decoration: none;
  color: #000;
}
.small_item_hover:hover {
  font-weight: 600;
}
.active_small {
  font-weight: 700;
}
.small_item_main {
  font-size: 19px;
}
.sety {
  margin-right: 28px;
  width: 30px;
  height: 30px;
}
.footer {
  position: absolute;
  font-weight: 300;
  font-size: 20px;
  width: 100%;
  max-width: 380px;
  height: 11.5vh;
  /* padding-top: 12px; */
  text-align: end;
  bottom: 4px;
}
.tel,
.tel:hover,
.tel.active {
  color: #000;
  text-decoration: none;
  letter-spacing: 0.8px;
}
.white_menu {
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

/* ******************************************************** */

@media (max-width: 1450px) {
  .name,
  .name:hover {
    font-size: 39px;
  }
  .menu {
    min-height: 70px;
    text-align: end;
  }
  .item {
    font-size: 16px;
    width: auto;
    display: inline-block;
    margin-left: 120px;
  }
  .pad {
    padding-left: 10%;
  }
  .utp {
    font-size: 17px;
  }
  .call {
    width: 251px;
  }
  .sety {
    margin-right: 24px;
    width: 25px;
    height: 25px;
  }
  .tel {
  letter-spacing: 0.6px;
}
  .footer {
    font-size: 19px;
  }
}
