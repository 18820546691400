.for_main_imgs {
  padding: 0;
  height: 93vh;
}
.for_small_img {
  padding: 4px;
  text-align: center;
  height: 22.75vh;
}
.row_for_small {
  margin: 0;
  padding-top: 2vh;
  padding-right: 4%;
  padding-left: 0px;
}
.row_for_inf {
  margin: 0;
  padding-right: 4%;
  padding-left: 5px;
}
.small_img {
  filter: grayscale(100%);
  cursor: pointer;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center top;
}
.small_img_color {
  cursor: pointer;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.white_inf {
  font-size: 16px;
  text-align: left;
  opacity: 0;
  color: #000;
  background-color: #fff;
  height: auto;
  padding: 2% 5%;
  width: 100%;
  position: absolute;
  cursor: pointer;
  bottom: -30px;
}

.surprises {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 30;
}
.p {
  margin-bottom: 0.5rem;
  margin-top: 0.6rem;
}
@media (min-width: 960px) {
  .surprises:hover ~ .white_inf,
  .white_inf:hover {
    opacity: 1;
    transform: translateY(-30px);
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
  }
  .for_small_img:hover > .small_img {
    filter: none;
  }

  .filter:hover > .small_img {
    filter: none;
  }
}

/* **************************************************** */
@media (max-width: 960px) {
  .for_main_imgs {
    margin-top: 188px;
    height: 100%;
  }
  .for_small_img {
    height: 22.75vh;
    min-height: 200px;
  }
  .row_for_small {
    padding-top: 0px;
    padding-right: 0;
  }
  .surprises:hover ~ .white_inf {
    opacity: 1;
    transform: translateY(-30px);
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
  }
}

@media (max-width: 450px) {
}
