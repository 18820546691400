@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  }
}
.main {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}
.white {
  padding: 1% 5% 0 4%;
}